import React from 'react';
import Core from '@Cyclope/core';

function App() {
    return (
        <div className="App">
            <Core />
        </div>
    );
}

export default App;
